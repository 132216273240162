<template>
  <lf-card class="max-w-screen-md" data-cy="borrowerPlatformTab">
    <loader :isLoading="isSubmitting" />
    <form @submit.prevent="submit">
      <div class="pb-2 pt-8 space-y-5">
        <lf-switch
          name="data.is_redirect_to_borrower_platform_enabled"
          v-model="initialValues.data.is_redirect_to_borrower_platform_enabled"
        >
          <lf-h3>
            {{ $t("ORGANIZATION.ENABLE_BORROWER_PLATFORM") }}
          </lf-h3>
          <span class="mt-2">
            {{ $t("ORGANIZATION.ENABLE_BORROWER_PLATFORM_SUBTITLE") }}
          </span>
        </lf-switch>
      </div>
      <modal-buttons
        class="border-t py-6 mt-5 flex justify-end"
        only-one-button
        save-is-submit
        :disabled="isSubmitting"
        :save-button-text="$t('ORGANIZATION.API_CONFIG.SAVE_CHANGES')"
      />
    </form>
  </lf-card>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";
import { useClients } from "@/hooks/clients";
import { useStore } from "vuex";
import ModalButtons from "@/components/ui/ModalButtons.vue";

const { activeClient } = useClients();
const { dispatch } = useStore();

const initialValues = reactive({
  data: {
    is_redirect_to_borrower_platform_enabled:
      !!activeClient.value?.is_redirect_to_borrower_platform_enabled
  }
});

const { handleSubmit, isSubmitting } = useForm({
  initialValues
});

const submit = handleSubmit(async (values, actions) => {
  await dispatchAction(values, actions, "clients/updateClient");
  await dispatch("auth/getAuthClientSettings");
});
</script>
